import React from "react";
import { graphql } from "gatsby";
import NewsWrap from "../../components/NewsWrap";

const NewsPage = ({ data, location }) => (
  <NewsWrap
    data={data}
    location={location}
    node={data.allMarkdownRemark.edges[0].node}
  />
);

export default NewsPage;

export const query = graphql`
  query NewsPageHomeQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/(news)/[^/]+[.]md$/" } }
      limit: 2
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            intro
            date(formatString: "MMM DD, YYYY")
          }
          html
        }
      }
    }
  }
`;
